import NotFoundPage from "@/pages/404.vue";
import End from "@/pages/End";
import BrowserError from "@/pages/BrowserError";

var routes = [
    {
        path: "/",
        /**
         * 由此入口統一管理導向
         * 
         * - 不使用async，因async每次都會重新載入頁面，無法keep alive
         * - 需要async的頁面，可以在此resolve到該path，再由該path去做async
         */
        redirect: function ({ to, resolve, reject }) {
            resolve('/product')
        },
    },
    {
        path: "/product",
        asyncComponent: () => import("@/pages/Product"),
    },
    {
        path: "/product-check",
        asyncComponent: () => import("@/pages/ProductCheck"),
    },
    {
        path: "/thank/:id",
        asyncComponent: () => import("@/pages/Thank"),
    },
    {
        path: "/order",
        asyncComponent: () => import("@/pages/Order"),
    },
    {
        path: "/order/:id",
        asyncComponent: () => import("@/pages/OrderDetailed"),
    },
    {
        path: "/end",
        component: End,
    },
    {
        path: "/browser-error",
        component: BrowserError,
    },
    {
        path: "(.*)",
        component: NotFoundPage,
    },
];

export default routes;
